/* # Front-end Style Guide

## Layout

The designs were created to the following widths:

- Mobile: 375px
- Desktop: 1440px

> 💡 These are just the design sizes. Ensure content is responsive and meets WCAG requirements by testing the full range of screen sizes from 320px to large screens.

## Colors

- Green: hsl(75, 94%, 57%)

- White: hsl(0, 0%, 100%)

- Grey 700: hsl(0, 0%, 20%)
- Grey 800: hsl(0, 0%, 12%)
- Grey 900: hsl(0, 0%, 8%)

## Typography

### Body Copy

- Font size (paragraph): 14px

### Font

- Family: [Inter](https://fonts.google.com/specimen/Inter)
- Weights: 400, 600, 700 */

.social_link_component{
        background-color: #141414;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
}
