*{
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

.landing-component{
    background-image: url("../images/landing/pexels-pixabay-265125.jpg");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


/* .proj{
    background: url(/imgs/pexels-pixabay-162631.jpg) no-repeat center center fixed;
} */
.nav{
    margin-top: 15px;
    margin-right: 15%;
    float:right;
    
}

.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#procard{
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    height: max-content;

}
.links{
    margin-top: 10px;
}
a{
    margin: 0px 7px;
    color: black;
    text-decoration: none;  
}

a:hover{
    color:ghostwhite;
}

#logo{
    max-width: 20%;
    display: block;
}

.links{
    padding: 5px 5px;
    background-color: slategray;
    border-radius: 15px;
}

.upper{
    margin: 0 auto;
    border: 1px solid red;
    text-align: center;
    width: 33%;
    color: whitesmoke;
    clear: both;
}