/* # Front-end Style Guide

## Layout

The designs were created to the following widths:

- Mobile: 375px
- Desktop: 1440px

> 💡 These are just the design sizes. Ensure content is responsive and meets WCAG requirements by testing the full range of screen sizes from 320px to large screens.

## Colors

- White: hsl(0, 0%, 100%)

- Slate 300: hsl(212, 45%, 89%)
- Slate 500: hsl(216, 15%, 48%)
- Slate 900: hsl(218, 44%, 22%)

## Typography

### Body Copy

- Font size (paragraph): 15px

### Font

- Family: [Outfit](https://fonts.google.com/specimen/Outfit)
- Weights: 400, 700
 */

.qr-component{
    background-color: #D5E1EF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    }


#qrcode{
    border-radius: 5%;

}

p{
    color: slategray;
}
.attribution { 
    font-size: 11px; 
    text-align: center; }
.attribution a { 
    color: hsl(228, 45%, 44%); 
}