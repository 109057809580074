/* STYLE GUIDE 
## Colors

- Yellow: hsl(47, 88%, 63%)

- White: hsl(0, 0%, 100%)

- Gray 500: hsl(0, 0%, 42%)
- Gray 950: hsl(0, 0%, 7%)

## Typography

### Body Copy

- Font size (paragraph): 16px

### Font

- Family: [Figtree](https://fonts.google.com/specimen/Figtree)
- Weights: 500, 800
*/

.blog-component{
    background-color: hsl(47, 88%, 63%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}

.card{
    box-shadow: 7px 7px;
}


.attribution { 
    font-size: 11px; 
    text-align: center; 
}
.attribution a { 
    color: hsl(228, 45%, 44%); 
}

#b {
    mask-type:alpha
}